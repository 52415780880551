<template>
    <div
        class="sport-type-badge"
        :class="['--' + size, { '--orange': orange }]"
    >
        {{ title }}

        <SportLevelBadge :level="level" />
    </div>
</template>

<script setup>
defineProps({
    title: {
        type: String,
    },
    orange: {
        default: false,
    },
    level: {},
    size: {
        default: 'small',
    },
});

const router = useRouter();
</script>

<style lang="scss" scoped>
.sport-type-badge {
    font-weight: 600;
    font-size: 0.9rem;
    color: #89cbd2;
    letter-spacing: 0;
    text-align: center;
    padding: 0.8rem 1.8rem;
    border-radius: 3rem;
    background: rgba(#81cbd3, 0.15);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &.--small {
        padding: 0.2rem 0.7rem;
    }

    &.--orange {
        font-weight: 600;
        font-size: 0.9rem;
        border-radius: 2rem;
        color: #ffffff;
        background: var(--ion-color-primary);
    }

    :deep(.sport__badge) {
        margin-left: 0.4rem;
    }
}
</style>
